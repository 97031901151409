import React from 'react';

export const CategoryIconFAQ = ({ className }) => (
  <svg className={className} viewBox="0 0 300 156">
    <rect x="112" y="28" width="76" height="5" rx="2.5" fill="currentColor" />
    <path
      d="M62 69.0932C62 68.2729 62.665 67.6079 63.4853 67.6079H135.515C136.335 67.6079 137 68.2729 137 69.0932V69.0932C137 69.9135 136.335 70.5785 135.515 70.5785H63.4853C62.665 70.5785 62 69.9135 62 69.0932V69.0932Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M62 77.0146C62 76.1943 62.665 75.5293 63.4853 75.5293H119.515C120.335 75.5293 121 76.1943 121 77.0146V77.0146C121 77.8349 120.335 78.4999 119.515 78.4999H63.4853C62.665 78.4999 62 77.8349 62 77.0146V77.0146Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M62 84.9365C62 84.1162 62.665 83.4512 63.4853 83.4512H126.515C127.335 83.4512 128 84.1162 128 84.9365V84.9365C128 85.7568 127.335 86.4218 126.515 86.4218H63.4853C62.665 86.4218 62 85.7568 62 84.9365V84.9365Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M62 91.8676C62 91.0473 62.665 90.3823 63.4853 90.3823H122.515C123.335 90.3823 124 91.0473 124 91.8676V91.8676C124 92.6879 123.335 93.3529 122.515 93.3529H63.4853C62.665 93.3529 62 92.6879 62 91.8676V91.8676Z"
      fill="currentColor"
    />
    <path
      d="M62 104.74C62 103.92 62.665 103.255 63.4853 103.255H135.515C136.335 103.255 137 103.92 137 104.74V104.74C137 105.56 136.335 106.225 135.515 106.225H63.4853C62.665 106.225 62 105.56 62 104.74V104.74Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M62 112.662C62 111.841 62.665 111.176 63.4853 111.176H119.515C120.335 111.176 121 111.841 121 112.662V112.662C121 113.482 120.335 114.147 119.515 114.147H63.4853C62.665 114.147 62 113.482 62 112.662V112.662Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M62 120.583C62 119.763 62.665 119.098 63.4853 119.098H126.515C127.335 119.098 128 119.763 128 120.583V120.583C128 121.404 127.335 122.069 126.515 122.069H63.4853C62.665 122.069 62 121.404 62 120.583V120.583Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M62 127.515C62 126.694 62.665 126.029 63.4853 126.029H122.515C123.335 126.029 124 126.694 124 127.515V127.515C124 128.335 123.335 129 122.515 129H63.4853C62.665 129 62 128.335 62 127.515V127.515Z"
      fill="currentColor"
    />
    <path
      d="M162 69.0932C162 68.2729 162.665 67.6079 163.485 67.6079H235.515C236.335 67.6079 237 68.2729 237 69.0932V69.0932C237 69.9135 236.335 70.5785 235.515 70.5785H163.485C162.665 70.5785 162 69.9135 162 69.0932V69.0932Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M162 77.0146C162 76.1943 162.665 75.5293 163.485 75.5293H219.515C220.335 75.5293 221 76.1943 221 77.0146V77.0146C221 77.8349 220.335 78.4999 219.515 78.4999H163.485C162.665 78.4999 162 77.8349 162 77.0146V77.0146Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M162 84.9365C162 84.1162 162.665 83.4512 163.485 83.4512H226.515C227.335 83.4512 228 84.1162 228 84.9365V84.9365C228 85.7568 227.335 86.4218 226.515 86.4218H163.485C162.665 86.4218 162 85.7568 162 84.9365V84.9365Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M162 91.8676C162 91.0473 162.665 90.3823 163.485 90.3823H222.515C223.335 90.3823 224 91.0473 224 91.8676V91.8676C224 92.6879 223.335 93.3529 222.515 93.3529H163.485C162.665 93.3529 162 92.6879 162 91.8676V91.8676Z"
      fill="currentColor"
    />
    <path
      d="M162 104.74C162 103.92 162.665 103.255 163.485 103.255H235.515C236.335 103.255 237 103.92 237 104.74V104.74C237 105.56 236.335 106.225 235.515 106.225H163.485C162.665 106.225 162 105.56 162 104.74V104.74Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M162 112.662C162 111.841 162.665 111.176 163.485 111.176H219.515C220.335 111.176 221 111.841 221 112.662V112.662C221 113.482 220.335 114.147 219.515 114.147H163.485C162.665 114.147 162 113.482 162 112.662V112.662Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M162 120.583C162 119.763 162.665 119.098 163.485 119.098H226.515C227.335 119.098 228 119.763 228 120.583V120.583C228 121.404 227.335 122.069 226.515 122.069H163.485C162.665 122.069 162 121.404 162 120.583V120.583Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M162 127.515C162 126.694 162.665 126.029 163.485 126.029H222.515C223.335 126.029 224 126.694 224 127.515V127.515C224 128.335 223.335 129 222.515 129H163.485C162.665 129 162 128.335 162 127.515V127.515Z"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="95"
      y="43"
      width="110"
      height="4"
      rx="2"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="100"
      y="52"
      width="100"
      height="4"
      rx="2"
      fill="currentColor"
    />
  </svg>
);
