import React from 'react';

export const CategoryIconContents = ({ className }) => (
  <svg className={className} viewBox="0 0 300 156">
    <rect x="24" y="64" width="45" height="5" rx="2.5" fill="currentColor" />
    <path
      d="M24 76.5C24 75.1193 25.1193 74 26.5 74H96.5C97.8807 74 99 75.1193 99 76.5V76.5C99 77.8807 97.8807 79 96.5 79H26.5C25.1193 79 24 77.8807 24 76.5V76.5Z"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="24"
      y="86"
      width="84"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="24"
      y="94"
      width="70"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="24"
      y="102"
      width="76"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect x="24" y="112" width="20" height="3" rx="1.5" fill="currentColor" />
    <circle cx="30" cy="51" r="6" fill="currentColor" />
    <g opacity="0.4">
      <rect x="206" y="42" width="71" height="71" rx="2" fill="currentColor" />
      <rect x="206" y="42" width="71" height="71" rx="2" fill="currentColor" />
    </g>
    <g opacity="0.4">
      <rect x="150" y="32" width="51" height="51" rx="2" fill="currentColor" />
      <rect x="150" y="32" width="51" height="51" rx="2" fill="currentColor" />
    </g>
    <g opacity="0.4">
      <rect x="165" y="88" width="36" height="36" rx="2" fill="currentColor" />
      <rect x="165" y="88" width="36" height="36" rx="2" fill="currentColor" />
    </g>
  </svg>
);
