import React from 'react';

export const CategoryIconFeatures = ({ className }) => (
  <svg className={className} viewBox="0 0 300 156">
    <rect x="24" y="33" width="45" height="5" rx="2.5" fill="currentColor" />
    <path
      d="M24 45.5C24 44.1193 25.1193 43 26.5 43H96.5C97.8807 43 99 44.1193 99 45.5V45.5C99 46.8807 97.8807 48 96.5 48H26.5C25.1193 48 24 46.8807 24 45.5V45.5Z"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="25"
      y="55"
      width="84"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="25"
      y="63"
      width="70"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="25"
      y="71"
      width="76"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect x="25" y="79" width="29" height="10" rx="2" fill="currentColor" />
    <rect x="60" y="82" width="20" height="3" rx="1.5" fill="currentColor" />
    <rect
      opacity="0.6"
      x="136"
      y="53"
      width="52"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="136"
      y="61"
      width="48"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="136"
      y="69"
      width="40"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect x="136" y="45" width="42" height="3" rx="1.5" fill="currentColor" />
    <circle cx="139.5" cy="36.5" r="3.5" fill="currentColor" />
    <rect
      opacity="0.6"
      x="136"
      y="104"
      width="52"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="136"
      y="112"
      width="48"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="136"
      y="120"
      width="40"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect x="136" y="96" width="42" height="3" rx="1.5" fill="currentColor" />
    <circle cx="139.5" cy="87.5" r="3.5" fill="currentColor" />
    <rect
      opacity="0.6"
      x="223"
      y="104"
      width="52"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="223"
      y="112"
      width="48"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="223"
      y="120"
      width="40"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect x="223" y="96" width="42" height="3" rx="1.5" fill="currentColor" />
    <circle cx="226.5" cy="87.5" r="3.5" fill="currentColor" />
    <rect
      opacity="0.6"
      x="223"
      y="53"
      width="52"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="223"
      y="61"
      width="48"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="223"
      y="69"
      width="40"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect x="223" y="45" width="42" height="3" rx="1.5" fill="currentColor" />
    <circle cx="226.5" cy="36.5" r="3.5" fill="currentColor" />
  </svg>
);
