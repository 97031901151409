export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const componentsCategoryTitleCase = (string) => {
  let title = capitalize(string);

  if (string === 'faq') {
    title = 'FAQs';
  }

  return title;
};
