import React from 'react';
import { useLocation } from '@reach/router';
import { ProductBreadcrumbs } from './ProductBreadcrumbs';
import { ComponentCategoryCard } from './ComponentCategoryCard';

const categoriesOrder = [
  'navs',
  'headers',
  'features',
  'contents',
  'pricings',
  'faq',
  'contacts',
  'steps',
  'teams',
  'blog',
  'stats',
  'footers',
];

export const ProductComponentCategories = ({
  productTitle,
  productUrl,
  categories,
}) => {
  const location = useLocation();

  const sortedCategories = categories.sort(
    (a, b) =>
      categoriesOrder.indexOf(a.category) - categoriesOrder.indexOf(b.category)
  );

  return (
    <div>
      <ProductBreadcrumbs
        productTitle={productTitle}
        productUrl={productUrl}
        breadcrumbs={[{ url: location.pathname, title: 'Components' }]}
      />
      <section className="mb-10">
        <div className="grid gap-8 row-gap-5 sm:grid-cols-3">
          {sortedCategories.map(({ category, totalCount }, index) => (
            <ComponentCategoryCard
              key={index}
              category={category}
              totalCount={totalCount}
              productUrl={productUrl}
            />
          ))}
        </div>
      </section>
    </div>
  );
};
