import React from 'react';

export const CategoryIconPricings = ({ className }) => (
  <svg className={className} viewBox="0 0 300 156">
    <rect x="112" y="23" width="76" height="5" rx="2.5" fill="currentColor" />
    <g opacity="0.3">
      <rect
        x="85"
        y="64"
        width="58.5246"
        height="70"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="85"
        y="64"
        width="58.5246"
        height="70"
        rx="2"
        fill="currentColor"
      />
    </g>
    <rect
      opacity="0.8"
      x="91.8867"
      y="89.2461"
      width="44.7541"
      height="2.29508"
      rx="1.14754"
      fill="currentColor"
    />
    <rect
      opacity="0.8"
      x="91.8867"
      y="96.1313"
      width="44.7541"
      height="2.29508"
      rx="1.14754"
      fill="currentColor"
    />
    <rect
      opacity="0.8"
      x="91.8867"
      y="103.017"
      width="44.7541"
      height="2.29508"
      rx="1.14754"
      fill="currentColor"
    />
    <rect
      opacity="0.8"
      x="91.8867"
      y="109.902"
      width="44.7541"
      height="2.29508"
      rx="1.14754"
      fill="currentColor"
    />
    <path
      d="M108.386 78.4254C108.386 79.0814 108.146 79.6454 107.666 80.1174C107.186 80.5814 106.494 80.8694 105.59 80.9814V82.3254H104.39V81.0174C103.798 80.9854 103.234 80.8814 102.698 80.7054C102.162 80.5294 101.73 80.3134 101.402 80.0574L102.062 78.5934C102.366 78.8174 102.722 79.0054 103.13 79.1574C103.538 79.3094 103.958 79.4054 104.39 79.4454V77.4414C103.822 77.3054 103.342 77.1534 102.95 76.9854C102.558 76.8174 102.23 76.5734 101.966 76.2534C101.702 75.9254 101.57 75.4934 101.57 74.9574C101.57 74.2934 101.81 73.7254 102.29 73.2534C102.778 72.7734 103.478 72.4814 104.39 72.3774V71.0454H105.59V72.3534C106.574 72.4334 107.386 72.6774 108.026 73.0854L107.426 74.5614C106.826 74.2174 106.214 74.0094 105.59 73.9374V75.9774C106.15 76.1054 106.622 76.2534 107.006 76.4214C107.398 76.5814 107.726 76.8254 107.99 77.1534C108.254 77.4734 108.386 77.8974 108.386 78.4254ZM103.502 74.8614C103.502 75.0614 103.578 75.2254 103.73 75.3534C103.882 75.4814 104.102 75.5894 104.39 75.6774V73.9734C104.086 74.0454 103.862 74.1614 103.718 74.3214C103.574 74.4734 103.502 74.6534 103.502 74.8614ZM105.59 79.3974C105.878 79.3334 106.094 79.2294 106.238 79.0854C106.382 78.9414 106.454 78.7694 106.454 78.5694C106.454 78.3694 106.378 78.2054 106.226 78.0774C106.082 77.9494 105.87 77.8374 105.59 77.7414V79.3974Z"
      fill="currentColor"
    />
    <path
      d="M113.564 75.9054C114.3 76.0254 114.864 76.3014 115.256 76.7334C115.648 77.1574 115.844 77.6854 115.844 78.3174C115.844 78.8054 115.716 79.2574 115.46 79.6734C115.204 80.0814 114.812 80.4094 114.284 80.6574C113.764 80.9054 113.124 81.0294 112.364 81.0294C111.772 81.0294 111.188 80.9534 110.612 80.8014C110.044 80.6414 109.56 80.4174 109.16 80.1294L109.916 78.6414C110.236 78.8814 110.604 79.0694 111.02 79.2054C111.444 79.3334 111.876 79.3974 112.316 79.3974C112.804 79.3974 113.188 79.3054 113.468 79.1214C113.748 78.9294 113.888 78.6614 113.888 78.3174C113.888 77.6294 113.364 77.2854 112.316 77.2854H111.428V76.0014L113.156 74.0454H109.58V72.4854H115.46V73.7454L113.564 75.9054Z"
      fill="currentColor"
    />
    <path
      d="M120.193 72.3414C121.369 72.3414 122.289 72.7014 122.953 73.4214C123.617 74.1334 123.949 75.1614 123.949 76.5054C123.949 77.4574 123.769 78.2734 123.409 78.9534C123.057 79.6334 122.557 80.1494 121.909 80.5014C121.261 80.8534 120.513 81.0294 119.665 81.0294C119.217 81.0294 118.789 80.9814 118.381 80.8854C117.973 80.7814 117.625 80.6374 117.337 80.4534L118.057 79.0254C118.465 79.3054 118.989 79.4454 119.629 79.4454C120.341 79.4454 120.905 79.2374 121.321 78.8214C121.737 78.3974 121.961 77.7894 121.993 76.9974C121.489 77.5014 120.793 77.7534 119.905 77.7534C119.369 77.7534 118.881 77.6454 118.441 77.4294C118.001 77.2134 117.653 76.9094 117.397 76.5174C117.141 76.1174 117.013 75.6574 117.013 75.1374C117.013 74.5774 117.153 74.0854 117.433 73.6614C117.713 73.2374 118.093 72.9134 118.573 72.6894C119.061 72.4574 119.601 72.3414 120.193 72.3414ZM120.337 76.3014C120.761 76.3014 121.105 76.1854 121.369 75.9534C121.641 75.7134 121.777 75.4094 121.777 75.0414C121.777 74.6734 121.645 74.3734 121.381 74.1414C121.125 73.9094 120.769 73.7934 120.313 73.7934C119.897 73.7934 119.557 73.9054 119.293 74.1294C119.037 74.3534 118.909 74.6574 118.909 75.0414C118.909 75.4254 119.037 75.7334 119.293 75.9654C119.557 76.1894 119.905 76.3014 120.337 76.3014Z"
      fill="currentColor"
    />
    <rect
      x="91.8867"
      y="119.082"
      width="44.7541"
      height="8.03279"
      rx="1"
      fill="currentColor"
    />
    <g opacity="0.3">
      <rect
        x="157.297"
        y="64"
        width="58.5246"
        height="70"
        rx="2"
        fill="currentColor"
      />
      <rect
        x="157.297"
        y="64"
        width="58.5246"
        height="70"
        rx="2"
        fill="currentColor"
      />
    </g>
    <rect
      opacity="0.8"
      x="164.184"
      y="89.2461"
      width="44.7541"
      height="2.29508"
      rx="1.14754"
      fill="currentColor"
    />
    <rect
      opacity="0.8"
      x="164.184"
      y="96.1313"
      width="44.7541"
      height="2.29508"
      rx="1.14754"
      fill="currentColor"
    />
    <rect
      opacity="0.8"
      x="164.184"
      y="103.017"
      width="44.7541"
      height="2.29508"
      rx="1.14754"
      fill="currentColor"
    />
    <rect
      opacity="0.8"
      x="164.184"
      y="109.902"
      width="44.7541"
      height="2.29508"
      rx="1.14754"
      fill="currentColor"
    />
    <path
      d="M180.683 78.4254C180.683 79.0814 180.443 79.6454 179.963 80.1174C179.483 80.5814 178.791 80.8694 177.887 80.9814V82.3254H176.687V81.0174C176.095 80.9854 175.531 80.8814 174.995 80.7054C174.459 80.5294 174.027 80.3134 173.699 80.0574L174.359 78.5934C174.663 78.8174 175.019 79.0054 175.427 79.1574C175.835 79.3094 176.255 79.4054 176.687 79.4454V77.4414C176.119 77.3054 175.639 77.1534 175.247 76.9854C174.855 76.8174 174.527 76.5734 174.263 76.2534C173.999 75.9254 173.867 75.4934 173.867 74.9574C173.867 74.2934 174.107 73.7254 174.587 73.2534C175.075 72.7734 175.775 72.4814 176.687 72.3774V71.0454H177.887V72.3534C178.871 72.4334 179.683 72.6774 180.323 73.0854L179.723 74.5614C179.123 74.2174 178.511 74.0094 177.887 73.9374V75.9774C178.447 76.1054 178.919 76.2534 179.303 76.4214C179.695 76.5814 180.023 76.8254 180.287 77.1534C180.551 77.4734 180.683 77.8974 180.683 78.4254ZM175.799 74.8614C175.799 75.0614 175.875 75.2254 176.027 75.3534C176.179 75.4814 176.399 75.5894 176.687 75.6774V73.9734C176.383 74.0454 176.159 74.1614 176.015 74.3214C175.871 74.4734 175.799 74.6534 175.799 74.8614ZM177.887 79.3974C178.175 79.3334 178.391 79.2294 178.535 79.0854C178.679 78.9414 178.751 78.7694 178.751 78.5694C178.751 78.3694 178.675 78.2054 178.523 78.0774C178.379 77.9494 178.167 77.8374 177.887 77.7414V79.3974Z"
      fill="currentColor"
    />
    <path
      d="M184.864 75.6414C186.048 75.6414 186.92 75.8774 187.48 76.3494C188.048 76.8214 188.332 77.4534 188.332 78.2454C188.332 78.7574 188.204 79.2254 187.948 79.6494C187.692 80.0654 187.3 80.4014 186.772 80.6574C186.252 80.9054 185.608 81.0294 184.84 81.0294C184.248 81.0294 183.664 80.9534 183.088 80.8014C182.52 80.6414 182.036 80.4174 181.636 80.1294L182.404 78.6414C182.724 78.8814 183.092 79.0694 183.508 79.2054C183.924 79.3334 184.352 79.3974 184.792 79.3974C185.28 79.3974 185.664 79.3014 185.944 79.1094C186.224 78.9174 186.364 78.6494 186.364 78.3054C186.364 77.9454 186.216 77.6734 185.92 77.4894C185.632 77.3054 185.128 77.2134 184.408 77.2134H182.26L182.692 72.4854H187.816V74.0454H184.312L184.18 75.6414H184.864Z"
      fill="currentColor"
    />
    <path
      d="M192.572 72.3414C193.748 72.3414 194.668 72.7014 195.332 73.4214C195.996 74.1334 196.328 75.1614 196.328 76.5054C196.328 77.4574 196.148 78.2734 195.788 78.9534C195.436 79.6334 194.936 80.1494 194.288 80.5014C193.64 80.8534 192.892 81.0294 192.044 81.0294C191.596 81.0294 191.168 80.9814 190.76 80.8854C190.352 80.7814 190.004 80.6374 189.716 80.4534L190.436 79.0254C190.844 79.3054 191.368 79.4454 192.008 79.4454C192.72 79.4454 193.284 79.2374 193.7 78.8214C194.116 78.3974 194.34 77.7894 194.372 76.9974C193.868 77.5014 193.172 77.7534 192.284 77.7534C191.748 77.7534 191.26 77.6454 190.82 77.4294C190.38 77.2134 190.032 76.9094 189.776 76.5174C189.52 76.1174 189.392 75.6574 189.392 75.1374C189.392 74.5774 189.532 74.0854 189.812 73.6614C190.092 73.2374 190.472 72.9134 190.952 72.6894C191.44 72.4574 191.98 72.3414 192.572 72.3414ZM192.716 76.3014C193.14 76.3014 193.484 76.1854 193.748 75.9534C194.02 75.7134 194.156 75.4094 194.156 75.0414C194.156 74.6734 194.024 74.3734 193.76 74.1414C193.504 73.9094 193.148 73.7934 192.692 73.7934C192.276 73.7934 191.936 73.9054 191.672 74.1294C191.416 74.3534 191.288 74.6574 191.288 75.0414C191.288 75.4254 191.416 75.7334 191.672 75.9654C191.936 76.1894 192.284 76.3014 192.716 76.3014Z"
      fill="currentColor"
    />
    <rect
      x="164.184"
      y="119.082"
      width="44.7541"
      height="8.03279"
      rx="1"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="95"
      y="38"
      width="110"
      height="4"
      rx="2"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="100"
      y="47"
      width="100"
      height="4"
      rx="2"
      fill="currentColor"
    />
  </svg>
);
