import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

export const ProductBreadcrumbs = ({
  productUrl,
  productTitle,
  breadcrumbs,
}) => (
  <ul className="flex items-center pt-6 mb-5 space-x-2 border-t sm:space-x-3">
    <li className="truncate">
      <Link
        to={productUrl}
        aria-label={productTitle}
        title={productTitle}
        className="text-sm font-medium text-gray-600 transition-colors duration-200 hover:text-indigo-700 sm:text-base"
      >
        {productTitle}
      </Link>
    </li>
    {breadcrumbs.map((item, index) => (
      <Fragment key={index}>
        <li className="truncate">
          <svg className="w-2 text-gray-500 sm:w-3" viewBox="0 0 24 24">
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="7,2 17,12 7,22"
              strokeLinejoin="round"
            />
          </svg>
        </li>
        <li className="truncate">
          <Link
            to={item.url}
            aria-label={item.title}
            title={item.title}
            className={classNames(
              'text-sm sm:text-base',
              index === breadcrumbs.length - 1
                ? 'font-semibold text-gray-800'
                : 'text-gray-600 transition-colors duration-200 font-medium hover:text-indigo-700'
            )}
          >
            {item.title}
          </Link>
        </li>
      </Fragment>
    ))}
  </ul>
);
