import React from 'react';

export const CategoryIconHeaders = ({ className }) => (
  <svg className={className} viewBox="0 0 300 156">
    <circle cx="149" cy="42" r="7" fill="currentColor" />
    <rect x="96" y="59" width="108" height="5" rx="2.5" fill="currentColor" />
    <path
      d="M113 71.5C113 70.1193 114.119 69 115.5 69H185.5C186.881 69 188 70.1193 188 71.5V71.5C188 72.8807 186.881 74 185.5 74H115.5C114.119 74 113 72.8807 113 71.5V71.5Z"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="71"
      y="84"
      width="157"
      height="5"
      rx="2.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="78"
      y="94"
      width="143"
      height="5"
      rx="2.5"
      fill="currentColor"
    />
    <rect x="129" y="109" width="40" height="14" rx="2" fill="currentColor" />
  </svg>
);
