import React from 'react';

export const CategoryIconTeams = ({ className }) => (
  <svg className={className} viewBox="0 0 300 156">
    <rect x="112" y="27" width="76" height="5" rx="2.5" fill="currentColor" />
    <rect
      opacity="0.6"
      x="95"
      y="42"
      width="110"
      height="4"
      rx="2"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="100"
      y="51"
      width="100"
      height="4"
      rx="2"
      fill="currentColor"
    />
    <circle opacity="0.6" cx="69" cy="73" r="6" fill="currentColor" />
    <path
      d="M63 84.5C63 83.6716 63.6716 83 64.5 83H94.5C95.3284 83 96 83.6716 96 84.5V84.5C96 85.3284 95.3284 86 94.5 86H64.5C63.6716 86 63 85.3284 63 84.5V84.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M63 91.5C63 90.6716 63.6716 90 64.5 90H91.5C92.3284 90 93 90.6716 93 91.5V91.5C93 92.3284 92.3284 93 91.5 93H64.5C63.6716 93 63 92.3284 63 91.5V91.5Z"
      fill="currentColor"
    />
    <circle opacity="0.6" cx="116" cy="73" r="6" fill="currentColor" />
    <path
      d="M110 84.5C110 83.6716 110.672 83 111.5 83H141.5C142.328 83 143 83.6716 143 84.5V84.5C143 85.3284 142.328 86 141.5 86H111.5C110.672 86 110 85.3284 110 84.5V84.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M110 91.5C110 90.6716 110.672 90 111.5 90H138.5C139.328 90 140 90.6716 140 91.5V91.5C140 92.3284 139.328 93 138.5 93H111.5C110.672 93 110 92.3284 110 91.5V91.5Z"
      fill="currentColor"
    />
    <circle opacity="0.6" cx="163" cy="73" r="6" fill="currentColor" />
    <path
      d="M157 84.5C157 83.6716 157.672 83 158.5 83H188.5C189.328 83 190 83.6716 190 84.5V84.5C190 85.3284 189.328 86 188.5 86H158.5C157.672 86 157 85.3284 157 84.5V84.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M157 91.5C157 90.6716 157.672 90 158.5 90H185.5C186.328 90 187 90.6716 187 91.5V91.5C187 92.3284 186.328 93 185.5 93H158.5C157.672 93 157 92.3284 157 91.5V91.5Z"
      fill="currentColor"
    />
    <circle opacity="0.6" cx="210" cy="73" r="6" fill="currentColor" />
    <path
      d="M204 84.5C204 83.6716 204.672 83 205.5 83H235.5C236.328 83 237 83.6716 237 84.5V84.5C237 85.3284 236.328 86 235.5 86H205.5C204.672 86 204 85.3284 204 84.5V84.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M204 91.5C204 90.6716 204.672 90 205.5 90H232.5C233.328 90 234 90.6716 234 91.5V91.5C234 92.3284 233.328 93 232.5 93H205.5C204.672 93 204 92.3284 204 91.5V91.5Z"
      fill="currentColor"
    />
    <circle opacity="0.6" cx="69" cy="109" r="6" fill="currentColor" />
    <path
      d="M63 120.5C63 119.672 63.6716 119 64.5 119H94.5C95.3284 119 96 119.672 96 120.5V120.5C96 121.328 95.3284 122 94.5 122H64.5C63.6716 122 63 121.328 63 120.5V120.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M63 127.5C63 126.672 63.6716 126 64.5 126H91.5C92.3284 126 93 126.672 93 127.5V127.5C93 128.328 92.3284 129 91.5 129H64.5C63.6716 129 63 128.328 63 127.5V127.5Z"
      fill="currentColor"
    />
    <circle opacity="0.6" cx="116" cy="109" r="6" fill="currentColor" />
    <path
      d="M110 120.5C110 119.672 110.672 119 111.5 119H141.5C142.328 119 143 119.672 143 120.5V120.5C143 121.328 142.328 122 141.5 122H111.5C110.672 122 110 121.328 110 120.5V120.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M110 127.5C110 126.672 110.672 126 111.5 126H138.5C139.328 126 140 126.672 140 127.5V127.5C140 128.328 139.328 129 138.5 129H111.5C110.672 129 110 128.328 110 127.5V127.5Z"
      fill="currentColor"
    />
    <circle opacity="0.6" cx="163" cy="109" r="6" fill="currentColor" />
    <path
      d="M157 120.5C157 119.672 157.672 119 158.5 119H188.5C189.328 119 190 119.672 190 120.5V120.5C190 121.328 189.328 122 188.5 122H158.5C157.672 122 157 121.328 157 120.5V120.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M157 127.5C157 126.672 157.672 126 158.5 126H185.5C186.328 126 187 126.672 187 127.5V127.5C187 128.328 186.328 129 185.5 129H158.5C157.672 129 157 128.328 157 127.5V127.5Z"
      fill="currentColor"
    />
    <circle opacity="0.6" cx="210" cy="109" r="6" fill="currentColor" />
    <path
      d="M204 120.5C204 119.672 204.672 119 205.5 119H235.5C236.328 119 237 119.672 237 120.5V120.5C237 121.328 236.328 122 235.5 122H205.5C204.672 122 204 121.328 204 120.5V120.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M204 127.5C204 126.672 204.672 126 205.5 126H232.5C233.328 126 234 126.672 234 127.5V127.5C234 128.328 233.328 129 232.5 129H205.5C204.672 129 204 128.328 204 127.5V127.5Z"
      fill="currentColor"
    />
  </svg>
);
