import React from 'react';
import { graphql } from 'gatsby';
import { LayoutKometa } from '../../../layouts/LayoutKometa';
import { ProductKometa } from '../../../products/kometa';
import { ProductComponentCategories } from '../../../components/ProductComponentCategories';

const KometaComponentsPage = ({ data }) => (
  <LayoutKometa>
    <ProductComponentCategories
      categories={data.allMdx.group}
      productTitle={ProductKometa.title}
      productUrl={ProductKometa.url}
    />
  </LayoutKometa>
);

export default KometaComponentsPage;

export const pageQuery = graphql`
  query KometaComponentsPage {
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/(components/kometa)/" } }
    ) {
      group(field: frontmatter___category) {
        category: fieldValue
        totalCount
      }
    }
  }
`;
