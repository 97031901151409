import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { LayoutApp } from './LayoutApp';
import { SEO } from '../components/SEO';
import { ProductHeader } from '../components/ProductHeader';
import { ProductKometa } from '../products/kometa';

export const LayoutKometa = ({ children }) => {
  const { image } = useStaticQuery(
    graphql`
      query {
        image: file(absolutePath: { regex: "/kometa-sections.png/" }) {
          childImageSharp {
            fluid(maxWidth: 512, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    `
  );

  return (
    <LayoutApp>
      <SEO title={ProductKometa.title} />
      <ProductHeader
        image={image.childImageSharp.fluid}
        title={ProductKometa.title}
        url={ProductKometa.url}
        price={ProductKometa.price}
        description={
          <>
            <p className="text-gray-700 xl:text-lg">
              We are giving away our first UI kit for free. It includes over{' '}
              <b className="font-semibold">130 sections</b>, built with
              TailwindCSS, for your awesome projects.
            </p>

            <p className="text-gray-700 xl:text-lg">
              All the sections are fully responsive and available in{' '}
              <b className="font-semibold">HTML</b>,{' '}
              <b className="font-semibold">VueJS</b>, and{' '}
              <b className="font-semibold">React.</b> <br />
              You can use them on unlimited personal and commercial projects.
            </p>
          </>
        }
      />
      {children}
    </LayoutApp>
  );
};
