import React from 'react';
import { Link } from 'gatsby';
import { CategoryIconHeaders } from './component-category-icons/CategoryIconHeaders';
import { CategoryIconNavs } from './component-category-icons/CategoryIconNavs';
import { CategoryIconFeatures } from './component-category-icons/CategoryIconFeatures';
import { CategoryIconContents } from './component-category-icons/CategoryIconContents';
import { CategoryIconFooters } from './component-category-icons/CategoryIconFooters';
import { CategoryIconFAQ } from './component-category-icons/CategoryIconFAQ';
import { CategoryIconBlog } from './component-category-icons/CategoryIconBlog';
import { CategoryIconStats } from './component-category-icons/CategoryIconStats';
import { CategoryIconContacts } from './component-category-icons/CategoryIconContacts';
import { CategoryIconPricings } from './component-category-icons/CategoryIconPricings';
import { CategoryIconSteps } from './component-category-icons/CategoryIconSteps';
import { CategoryIconTeams } from './component-category-icons/CategoryIconTeams';
import { componentsCategoryTitleCase } from '../utils/string';

const categoryIcons = {
  headers: CategoryIconHeaders,
  navs: CategoryIconNavs,
  features: CategoryIconFeatures,
  contents: CategoryIconContents,
  footers: CategoryIconFooters,
  faq: CategoryIconFAQ,
  blog: CategoryIconBlog,
  stats: CategoryIconStats,
  contacts: CategoryIconContacts,
  pricings: CategoryIconPricings,
  steps: CategoryIconSteps,
  teams: CategoryIconTeams,
};

const Card = ({ children, icon, title }) => (
  <div className="h-full overflow-hidden transition-colors duration-300 border border-gray-400 rounded shadow-sm hover:border-indigo-600 group">
    <div className="text-gray-500 transition-colors duration-300 bg-gray-200 group-hover:bg-indigo-100 group-hover:text-indigo-600">
      {icon}
    </div>
    <div className="px-5 py-4 transition-colors duration-300 border-t border-gray-400 group-hover:border-indigo-600">
      <div className="flex items-center justify-between mb-px">
        <header className="font-semibold transition-colors duration-300 xl:text-lg group-hover:text-indigo-700">
          {title}
        </header>
        <div>
          <svg
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeMiterlimit="10"
            strokeLinejoin="round"
            viewBox="0 0 24 24"
            className="w-5 text-gray-500 transition-colors duration-300 group-hover:text-indigo-600"
          >
            <line x1="2" y1="12" x2="22" y2="12" />
            <polyline points="15,5 22,12 15,19" />
          </svg>
        </div>
      </div>
      <p className="sm:text-sm xl:text-base">{children}</p>
    </div>
  </div>
);

export const ComponentCategoryCard = ({ productUrl, category, totalCount }) => {
  const CategoryIcon = categoryIcons[category];

  const categoryTitle = componentsCategoryTitleCase(category);

  return (
    <Link
      aria-label={`View ${categoryTitle}`}
      title={`View ${categoryTitle}`}
      to={`${productUrl}/components/${category}`}
    >
      <Card
        title={categoryTitle}
        alignIcon="start"
        icon={
          <CategoryIcon className="w-full text-gray-700 transition-all duration-200 opacity-25 group-hover:text-gray-800" />
        }
      >
        {totalCount} components
      </Card>
    </Link>
  );
};
