import React from 'react';

export const CategoryIconNavs = ({ className }) => (
  <svg className={className} viewBox="0 0 300 156">
    <circle cx="16" cy="17" r="6" fill="currentColor" />
    <rect
      opacity="0.6"
      x="234"
      y="15"
      width="20"
      height="4"
      rx="2"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="207"
      y="15"
      width="20"
      height="4"
      rx="2"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="179"
      y="15"
      width="20"
      height="4"
      rx="2"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="152"
      y="15"
      width="20"
      height="4"
      rx="2"
      fill="currentColor"
    />
    <rect x="261" y="12" width="29" height="10" rx="2" fill="currentColor" />
    <line x1="10" y1="30.5" x2="290" y2="30.5" opacity="0.4" stroke="currentColor" />
  </svg>
);
