import React from 'react';

export const CategoryIconStats = ({ className }) => (
  <svg className={className} viewBox="0 0 300 156">
    <rect
      opacity="0.6"
      x="29"
      y="85"
      width="28"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <path
      d="M28.4363 68.4754V78.7582H26.0566V70.385H24V68.4754H28.4363Z"
      fill="currentColor"
    />
    <path
      d="M40.2508 76.5988H38.5761V78.7582H36.2552V76.5988H30.7171V74.9976L35.5647 68.4754H38.062L33.5669 74.6597H36.3286V72.7354H38.5761V74.6597H40.2508V76.5988Z"
      fill="currentColor"
    />
    <path
      d="M50.9907 76.5988H49.3161V78.7582H46.9951V76.5988H41.4571V74.9976L46.3047 68.4754H48.8019L44.3069 74.6597H47.0685V72.7354H49.3161V74.6597H50.9907V76.5988Z"
      fill="currentColor"
    />
    <path
      d="M56.1926 75.6586L55.0909 76.7457V78.7582H52.7993V67.8584H55.0909V74.0281L58.4401 70.8551H61.1724L57.8819 74.2044L61.4662 78.7582H58.6899L56.1926 75.6586Z"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="96"
      y="85"
      width="26"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <path
      d="M93.323 68.4756V78.7584H90.9433V70.3853H88.8867V68.4756H93.323Z"
      fill="currentColor"
    />
    <path
      d="M103.404 76.8193V78.7584H95.6332V77.216L99.5995 73.4701C100.021 73.0686 100.305 72.7258 100.451 72.4418C100.598 72.148 100.672 71.8591 100.672 71.5751C100.672 71.1638 100.53 70.8504 100.246 70.635C99.9716 70.4097 99.5652 70.2971 99.0266 70.2971C98.5761 70.2971 98.1697 70.3853 97.8073 70.5615C97.445 70.728 97.1414 70.9826 96.8965 71.3254L95.1632 70.209C95.5647 69.6116 96.118 69.1464 96.8231 68.8135C97.5282 68.4707 98.3361 68.2993 99.2469 68.2993C100.011 68.2993 100.677 68.4266 101.245 68.6812C101.822 68.9261 102.268 69.2786 102.581 69.7389C102.905 70.1894 103.066 70.7231 103.066 71.3401C103.066 71.8983 102.949 72.4222 102.714 72.9119C102.479 73.4015 102.023 73.9597 101.348 74.5865L98.9825 76.8193H103.404Z"
      fill="currentColor"
    />
    <path
      d="M106.493 78.8759C106.091 78.8759 105.754 78.7388 105.479 78.4646C105.205 78.1904 105.068 77.8476 105.068 77.4363C105.068 77.0152 105.205 76.6773 105.479 76.4227C105.754 76.1583 106.091 76.0261 106.493 76.0261C106.895 76.0261 107.232 76.1583 107.507 76.4227C107.781 76.6773 107.918 77.0152 107.918 77.4363C107.918 77.8476 107.781 78.1904 107.507 78.4646C107.232 78.7388 106.895 78.8759 106.493 78.8759Z"
      fill="currentColor"
    />
    <path
      d="M113.212 68.2993C114.651 68.2993 115.778 68.74 116.59 69.6214C117.403 70.493 117.81 71.7514 117.81 73.3966C117.81 74.562 117.589 75.5609 117.149 76.3933C116.718 77.2258 116.106 77.8574 115.312 78.2883C114.519 78.7192 113.604 78.9347 112.565 78.9347C112.017 78.9347 111.493 78.8759 110.994 78.7584C110.494 78.6311 110.068 78.4548 109.716 78.2296L110.597 76.4815C111.097 76.8242 111.738 76.9956 112.521 76.9956C113.393 76.9956 114.083 76.741 114.593 76.2318C115.102 75.7127 115.376 74.9684 115.415 73.9989C114.798 74.6159 113.946 74.9244 112.859 74.9244C112.203 74.9244 111.606 74.7922 111.067 74.5278C110.529 74.2633 110.103 73.8912 109.789 73.4113C109.476 72.9217 109.319 72.3586 109.319 71.722C109.319 71.0365 109.49 70.4342 109.833 69.9152C110.176 69.3961 110.641 68.9995 111.229 68.7253C111.826 68.4413 112.487 68.2993 113.212 68.2993ZM113.388 73.1469C113.907 73.1469 114.328 73.0049 114.651 72.7209C114.984 72.4271 115.151 72.055 115.151 71.6045C115.151 71.154 114.989 70.7868 114.666 70.5028C114.353 70.2188 113.917 70.0768 113.359 70.0768C112.849 70.0768 112.433 70.2139 112.11 70.4881C111.797 70.7623 111.64 71.1344 111.64 71.6045C111.64 72.0746 111.797 72.4516 112.11 72.7356C112.433 73.0098 112.859 73.1469 113.388 73.1469Z"
      fill="currentColor"
    />
    <path
      d="M123.956 74.7187L122.575 76.1583V78.7584H120.21V68.4756H122.575V73.2791L127.129 68.4756H129.773L125.513 73.0588L130.023 78.7584H127.246L123.956 74.7187Z"
      fill="currentColor"
    />
    <line
      opacity="0.4"
      x1="75.5"
      y1="65"
      x2="75.5"
      y2="91"
      stroke="currentColor"
    />
    <rect
      opacity="0.6"
      x="167"
      y="85"
      width="26"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <path
      d="M167.319 76.599H165.644V78.7584H163.323V76.599H157.785V74.9978L162.633 68.4756H165.13L160.635 74.66H163.397V72.7356H165.644V74.66H167.319V76.599Z"
      fill="currentColor"
    />
    <path
      d="M175.822 73.3526C176.332 73.6072 176.719 73.9402 176.983 74.3515C177.257 74.7628 177.394 75.2378 177.394 75.7764C177.394 76.4129 177.213 76.9711 176.851 77.451C176.488 77.9211 175.979 78.2883 175.323 78.5527C174.667 78.8074 173.908 78.9347 173.046 78.9347C172.184 78.9347 171.425 78.8074 170.769 78.5527C170.123 78.2883 169.618 77.9211 169.256 77.451C168.904 76.9711 168.727 76.4129 168.727 75.7764C168.727 75.2378 168.86 74.7628 169.124 74.3515C169.398 73.9402 169.785 73.6072 170.284 73.3526C169.902 73.1175 169.609 72.8237 169.403 72.4712C169.197 72.1088 169.095 71.6975 169.095 71.2373C169.095 70.6399 169.261 70.1208 169.594 69.6801C169.927 69.2395 170.392 68.9016 170.99 68.6666C171.587 68.4217 172.272 68.2993 173.046 68.2993C173.83 68.2993 174.52 68.4217 175.117 68.6666C175.725 68.9016 176.195 69.2395 176.528 69.6801C176.861 70.1208 177.027 70.6399 177.027 71.2373C177.027 71.6975 176.924 72.1088 176.719 72.4712C176.513 72.8237 176.214 73.1175 175.822 73.3526ZM173.046 70.0474C172.566 70.0474 172.179 70.1649 171.886 70.3999C171.602 70.6252 171.46 70.9386 171.46 71.3401C171.46 71.7318 171.602 72.0403 171.886 72.2655C172.17 72.4908 172.556 72.6034 173.046 72.6034C173.536 72.6034 173.927 72.4908 174.221 72.2655C174.515 72.0403 174.662 71.7318 174.662 71.3401C174.662 70.9386 174.515 70.6252 174.221 70.3999C173.927 70.1649 173.536 70.0474 173.046 70.0474ZM173.046 77.1866C173.643 77.1866 174.114 77.0544 174.456 76.79C174.809 76.5158 174.985 76.1534 174.985 75.7029C174.985 75.2524 174.809 74.895 174.456 74.6306C174.114 74.3662 173.643 74.234 173.046 74.234C172.458 74.234 171.993 74.3662 171.651 74.6306C171.308 74.895 171.136 75.2524 171.136 75.7029C171.136 76.1632 171.308 76.5256 171.651 76.79C171.993 77.0544 172.458 77.1866 173.046 77.1866Z"
      fill="currentColor"
    />
    <path
      d="M180.591 78.8759C180.189 78.8759 179.851 78.7388 179.577 78.4646C179.303 78.1904 179.166 77.8476 179.166 77.4363C179.166 77.0152 179.303 76.6773 179.577 76.4227C179.851 76.1583 180.189 76.0261 180.591 76.0261C180.992 76.0261 181.33 76.1583 181.604 76.4227C181.878 76.6773 182.015 77.0152 182.015 77.4363C182.015 77.8476 181.878 78.1904 181.604 78.4646C181.33 78.7388 180.992 78.8759 180.591 78.8759Z"
      fill="currentColor"
    />
    <path
      d="M188.643 72.6622C189.544 72.8091 190.234 73.1469 190.714 73.6757C191.194 74.1948 191.434 74.8411 191.434 75.6148C191.434 76.2122 191.277 76.7655 190.964 77.2747C190.65 77.7742 190.17 78.1757 189.524 78.4793C188.888 78.7829 188.104 78.9347 187.174 78.9347C186.449 78.9347 185.734 78.8416 185.029 78.6556C184.334 78.4597 183.741 78.1855 183.252 77.8329L184.177 76.0114C184.569 76.3052 185.019 76.5353 185.529 76.7018C186.048 76.8585 186.576 76.9369 187.115 76.9369C187.712 76.9369 188.182 76.8242 188.525 76.599C188.868 76.364 189.039 76.0359 189.039 75.6148C189.039 74.7726 188.398 74.3515 187.115 74.3515H186.028V72.7797L188.143 70.3853H183.766V68.4756H190.964V70.018L188.643 72.6622Z"
      fill="currentColor"
    />
    <path
      d="M197.537 74.7187L196.156 76.1583V78.7584H193.791V68.4756H196.156V73.2791L200.71 68.4756H203.354L199.094 73.0588L203.604 78.7584H200.827L197.537 74.7187Z"
      fill="currentColor"
    />
    <line
      opacity="0.4"
      x1="144.5"
      y1="65"
      x2="144.5"
      y2="91"
      stroke="currentColor"
    />
    <rect
      opacity="0.6"
      x="241"
      y="85"
      width="28"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <path
      d="M240.257 76.8193V78.7584H232.486V77.216L236.452 73.4701C236.873 73.0686 237.157 72.7258 237.304 72.4418C237.451 72.148 237.524 71.8591 237.524 71.5751C237.524 71.1638 237.382 70.8504 237.098 70.635C236.824 70.4097 236.418 70.2971 235.879 70.2971C235.429 70.2971 235.022 70.3853 234.66 70.5615C234.297 70.728 233.994 70.9826 233.749 71.3254L232.016 70.209C232.417 69.6116 232.97 69.1464 233.676 68.8135C234.381 68.4707 235.189 68.2993 236.099 68.2993C236.863 68.2993 237.529 68.4266 238.097 68.6812C238.675 68.9261 239.121 69.2786 239.434 69.7389C239.757 70.1894 239.919 70.7231 239.919 71.3401C239.919 71.8983 239.801 72.4222 239.566 72.9119C239.331 73.4015 238.876 73.9597 238.2 74.5865L235.835 76.8193H240.257Z"
      fill="currentColor"
    />
    <path
      d="M251.123 76.599H249.449V78.7584H247.128V76.599H241.59V74.9978L246.437 68.4756H248.934L244.439 74.66H247.201V72.7356H249.449V74.66H251.123V76.599Z"
      fill="currentColor"
    />
    <path
      d="M254.085 78.8759C253.684 78.8759 253.346 78.7388 253.072 78.4646C252.798 78.1904 252.66 77.8476 252.66 77.4363C252.66 77.0152 252.798 76.6773 253.072 76.4227C253.346 76.1583 253.684 76.0261 254.085 76.0261C254.487 76.0261 254.825 76.1583 255.099 76.4227C255.373 76.6773 255.51 77.0152 255.51 77.4363C255.51 77.8476 255.373 78.1904 255.099 78.4646C254.825 78.7388 254.487 78.8759 254.085 78.8759Z"
      fill="currentColor"
    />
    <path
      d="M260.716 72.339C262.165 72.339 263.233 72.6279 263.918 73.2057C264.614 73.7835 264.961 74.5571 264.961 75.5267C264.961 76.1534 264.805 76.7263 264.491 77.2453C264.178 77.7546 263.698 78.1659 263.052 78.4793C262.415 78.7829 261.627 78.9347 260.687 78.9347C259.962 78.9347 259.247 78.8416 258.542 78.6556C257.847 78.4597 257.254 78.1855 256.765 77.8329L257.705 76.0114C258.096 76.3052 258.547 76.5353 259.056 76.7018C259.565 76.8585 260.089 76.9369 260.628 76.9369C261.225 76.9369 261.695 76.8193 262.038 76.5843C262.381 76.3493 262.552 76.0212 262.552 75.6001C262.552 75.1594 262.371 74.8264 262.009 74.6012C261.656 74.376 261.039 74.2633 260.158 74.2633H257.528L258.057 68.4756H264.33V70.3853H260.04L259.879 72.339H260.716Z"
      fill="currentColor"
    />
    <path
      d="M270.931 74.7187L269.55 76.1583V78.7584H267.185V68.4756H269.55V73.2791L274.104 68.4756H276.748L272.488 73.0588L276.998 78.7584H274.222L270.931 74.7187Z"
      fill="currentColor"
    />
    <line
      opacity="0.4"
      x1="217.5"
      y1="65"
      x2="217.5"
      y2="91"
      stroke="currentColor"
    />
  </svg>
);
