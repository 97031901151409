import React from 'react';
import Image from 'gatsby-image/withIEPolyfill';
import { Link } from 'gatsby';

export const ProductHeader = ({ title, image, price, description, url }) => (
  <div className="flex flex-col mb-10 sm:flex-row sm:items-center">
    <div className="mb-8 sm:mb-0 sm:mr-6">
      <Link to={url} title={title} aria-label={title}>
        <Image
          fluid={image}
          draggable={false}
          alt={title}
          className="w-full rounded shadow-xl sm:w-64"
        />
      </Link>
    </div>
    <div>
      <div className="flex mb-4">
        <Link to={url} title={title} aria-label={title}>
          <h1 className="text-3xl font-extrabold leading-none xl:text-5xl">
            {title}
          </h1>
        </Link>
        <div className="ml-2 -mt-2">
          <p className="px-3 py-px text-xs font-semibold text-teal-700 bg-teal-200 rounded-full sm:text-sm">
            {price}
          </p>
        </div>
      </div>
      <div className="space-y-3 xl:space-y-4">{description}</div>
    </div>
  </div>
);
