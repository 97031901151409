import React from 'react';

export const CategoryIconSteps = ({ className }) => (
  <svg className={className} viewBox="0 0 300 156">
    <rect x="114" y="35" width="76" height="5" rx="2.5" fill="currentColor" />
    <rect
      opacity="0.6"
      x="97"
      y="50"
      width="110"
      height="4"
      rx="2"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="102"
      y="59"
      width="100"
      height="4"
      rx="2"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="59"
      y="103"
      width="52"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="63"
      y="111"
      width="44"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="65"
      y="119"
      width="40"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect x="64" y="95" width="42" height="3" rx="1.5" fill="currentColor" />
    <path d="M86.436 77V89.6H83.52V79.34H81V77H86.436Z" fill="currentColor" />
    <rect
      opacity="0.6"
      x="129"
      y="103"
      width="45"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="128"
      y="111"
      width="48"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="132"
      y="119"
      width="40"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect x="131" y="95" width="42" height="3" rx="1.5" fill="currentColor" />
    <path
      d="M157.098 87.44V89.816H147.576V87.926L152.436 83.336C152.952 82.844 153.3 82.424 153.48 82.076C153.66 81.716 153.75 81.362 153.75 81.014C153.75 80.51 153.576 80.126 153.228 79.862C152.892 79.586 152.394 79.448 151.734 79.448C151.182 79.448 150.684 79.556 150.24 79.772C149.796 79.976 149.424 80.288 149.124 80.708L147 79.34C147.492 78.608 148.17 78.038 149.034 77.63C149.898 77.21 150.888 77 152.004 77C152.94 77 153.756 77.156 154.452 77.468C155.16 77.768 155.706 78.2 156.09 78.764C156.486 79.316 156.684 79.97 156.684 80.726C156.684 81.41 156.54 82.052 156.252 82.652C155.964 83.252 155.406 83.936 154.578 84.704L151.68 87.44H157.098Z"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="193"
      y="103"
      width="48"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="193"
      y="111"
      width="48"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="197"
      y="119"
      width="40"
      height="3"
      rx="1.5"
      fill="currentColor"
    />
    <rect x="196" y="95" width="42" height="3" rx="1.5" fill="currentColor" />
    <path
      d="M218.606 82.13C219.71 82.31 220.556 82.724 221.144 83.372C221.732 84.008 222.026 84.8 222.026 85.748C222.026 86.48 221.834 87.158 221.45 87.782C221.066 88.394 220.478 88.886 219.686 89.258C218.906 89.63 217.946 89.816 216.806 89.816C215.918 89.816 215.042 89.702 214.178 89.474C213.326 89.234 212.6 88.898 212 88.466L213.134 86.234C213.614 86.594 214.166 86.876 214.79 87.08C215.426 87.272 216.074 87.368 216.734 87.368C217.466 87.368 218.042 87.23 218.462 86.954C218.882 86.666 219.092 86.264 219.092 85.748C219.092 84.716 218.306 84.2 216.734 84.2H215.402V82.274L217.994 79.34H212.63V77H221.45V78.89L218.606 82.13Z"
      fill="currentColor"
    />
  </svg>
);
