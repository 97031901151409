import React from 'react';

export const CategoryIconBlog = ({ className }) => (
  <svg className={className} viewBox="0 0 300 156">
    <rect x="112" y="29" width="76" height="5" rx="2.5" fill="currentColor" />
    <path
      d="M54 102.5C54 101.672 54.6716 101 55.5 101H89.5C90.3284 101 91 101.672 91 102.5V102.5C91 103.328 90.3284 104 89.5 104H55.5C54.6716 104 54 103.328 54 102.5V102.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M54 110.5C54 109.672 54.6716 109 55.5 109H87.5C88.3284 109 89 109.672 89 110.5V110.5C89 111.328 88.3284 112 87.5 112H55.5C54.6716 112 54 111.328 54 110.5V110.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M54 118.5C54 117.672 54.6716 117 55.5 117H91.5C92.3284 117 93 117.672 93 118.5V118.5C93 119.328 92.3284 120 91.5 120H55.5C54.6716 120 54 119.328 54 118.5V118.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M54 126.5C54 125.672 54.6716 125 55.5 125H80.5C81.3284 125 82 125.672 82 126.5V126.5C82 127.328 81.3284 128 80.5 128H55.5C54.6716 128 54 127.328 54 126.5V126.5Z"
      fill="currentColor"
    />
    <g opacity="0.4">
      <rect x="54" y="69" width="41" height="26" rx="2" fill="currentColor" />
      <rect x="54" y="69" width="41" height="26" rx="2" fill="currentColor" />
    </g>
    <path
      d="M104 102.5C104 101.672 104.672 101 105.5 101H138.5C139.328 101 140 101.672 140 102.5C140 103.328 139.328 104 138.5 104H105.5C104.672 104 104 103.328 104 102.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M104 110.5C104 109.672 104.672 109 105.5 109H135.5C136.328 109 137 109.672 137 110.5C137 111.328 136.328 112 135.5 112H105.5C104.672 112 104 111.328 104 110.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M104 118.5C104 117.672 104.672 117 105.5 117H138.5C139.328 117 140 117.672 140 118.5C140 119.328 139.328 120 138.5 120H105.5C104.672 120 104 119.328 104 118.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M104 126.5C104 125.672 104.672 125 105.5 125H130.5C131.328 125 132 125.672 132 126.5V126.5C132 127.328 131.328 128 130.5 128H105.5C104.672 128 104 127.328 104 126.5V126.5Z"
      fill="currentColor"
    />
    <g opacity="0.4">
      <rect x="104" y="69" width="41" height="26" rx="2" fill="currentColor" />
      <rect x="104" y="69" width="41" height="26" rx="2" fill="currentColor" />
    </g>
    <path
      d="M154 102.5C154 101.672 154.672 101 155.5 101H185.5C186.328 101 187 101.672 187 102.5C187 103.328 186.328 104 185.5 104H155.5C154.672 104 154 103.328 154 102.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M154 110.5C154 109.672 154.672 109 155.5 109H190.5C191.328 109 192 109.672 192 110.5C192 111.328 191.328 112 190.5 112H155.5C154.672 112 154 111.328 154 110.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M154 118.5C154 117.672 154.672 117 155.5 117H189.5C190.328 117 191 117.672 191 118.5C191 119.328 190.328 120 189.5 120H155.5C154.672 120 154 119.328 154 118.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M154 126.5C154 125.672 154.672 125 155.5 125H178.5C179.328 125 180 125.672 180 126.5V126.5C180 127.328 179.328 128 178.5 128H155.5C154.672 128 154 127.328 154 126.5V126.5Z"
      fill="currentColor"
    />
    <g opacity="0.4">
      <rect x="154" y="69" width="41" height="26" rx="2" fill="currentColor" />
      <rect x="154" y="69" width="41" height="26" rx="2" fill="currentColor" />
    </g>
    <path
      d="M204 102.5C204 101.672 204.672 101 205.5 101H239.5C240.328 101 241 101.672 241 102.5C241 103.328 240.328 104 239.5 104H205.5C204.672 104 204 103.328 204 102.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M204 110.5C204 109.672 204.672 109 205.5 109H243.5C244.328 109 245 109.672 245 110.5C245 111.328 244.328 112 243.5 112H205.5C204.672 112 204 111.328 204 110.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M204 118.5C204 117.672 204.672 117 205.5 117H236.5C237.328 117 238 117.672 238 118.5C238 119.328 237.328 120 236.5 120H205.5C204.672 120 204 119.328 204 118.5Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      d="M204 126.5C204 125.672 204.672 125 205.5 125H230.5C231.328 125 232 125.672 232 126.5V126.5C232 127.328 231.328 128 230.5 128H205.5C204.672 128 204 127.328 204 126.5V126.5Z"
      fill="currentColor"
    />
    <g opacity="0.4">
      <rect x="204" y="69" width="41" height="26" rx="2" fill="currentColor" />
      <rect x="204" y="69" width="41" height="26" rx="2" fill="currentColor" />
    </g>
    <rect
      opacity="0.6"
      x="95"
      y="44"
      width="110"
      height="4"
      rx="2"
      fill="currentColor"
    />
    <rect
      opacity="0.6"
      x="100"
      y="53"
      width="100"
      height="4"
      rx="2"
      fill="currentColor"
    />
  </svg>
);
