import React from 'react';

export const CategoryIconContacts = ({ className }) => (
  <svg className={className} viewBox="0 0 300 156">
    <rect x="63" y="29" width="37" height="5" rx="2.5" fill="currentColor" />
    <rect
      opacity="0.6"
      x="63"
      y="39"
      width="57"
      height="5"
      rx="2.5"
      fill="currentColor"
    />
    <rect x="192" y="39" width="45" height="5" rx="2.5" fill="currentColor" />
    <rect
      opacity="0.6"
      x="192"
      y="49"
      width="40"
      height="5"
      rx="2.5"
      fill="currentColor"
    />
    <rect x="192" y="70" width="42" height="5" rx="2.5" fill="currentColor" />
    <rect
      opacity="0.6"
      x="192"
      y="80"
      width="32"
      height="5"
      rx="2.5"
      fill="currentColor"
    />
    <rect x="192" y="101" width="35" height="5" rx="2.5" fill="currentColor" />
    <rect
      opacity="0.6"
      x="192"
      y="111"
      width="40"
      height="5"
      rx="2.5"
      fill="currentColor"
    />
    <rect x="63" y="54" width="18" height="4" rx="2" fill="currentColor" />
    <rect
      opacity="0.4"
      x="63"
      y="61"
      width="72"
      height="7"
      rx="1"
      fill="currentColor"
    />
    <rect x="63" y="72" width="18" height="4" rx="2" fill="currentColor" />
    <rect
      opacity="0.4"
      x="63"
      y="79"
      width="72"
      height="7"
      rx="1"
      fill="currentColor"
    />
    <rect x="63" y="90" width="18" height="4" rx="2" fill="currentColor" />
    <rect
      opacity="0.4"
      x="63"
      y="97"
      width="72"
      height="18"
      rx="1"
      fill="currentColor"
    />
    <rect x="63" y="120" width="72" height="8" rx="1" fill="currentColor" />
  </svg>
);
